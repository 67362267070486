import { MobileOutlined, LaptopOutlined, CloudServerOutlined, CameraOutlined, ExclamationCircleFilled, ControlOutlined, AndroidOutlined, DatabaseOutlined, ArrowRightOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useRafInterval, useSize, useKeyPress } from "ahooks";
import { Row, Space, Alert, Slider, Button, Switch, Col } from "antd";
import { groupBy, range } from "lodash";
import { useMemo, useState, useRef, useCallback, CSSProperties } from "react";
import { KaceyView } from "../../../types";
import { defaultSimulationParams } from "./defaultSimulationParams";
import { BarData } from "./types";

const ArrowAnimation = () =>
    <div style={{ padding: 10, marginTop: "140px" }}>
        <ArrowRightOutlined style={{ position: "relative", top: 16, right: -3, fontSize: 29, zIndex: 0 }} />
        <div className="border-animation" style={{ height: 3, width: 25, textAlign: "right", zIndex: 0, position: "relative" }}>
        </div>
    </div>;


const Outage = ({ left }: { left?: number }) => <Alert key="alert" className="blink" style={{ position: "absolute", marginLeft: left, fontSize: 40, top: 140 }} type={"error"} message={
    <div className="blink" style={{ zIndex: 200, color: "crimson", fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
        <ExclamationCircleOutlined style={{ fontSize: 30 }} />
        <br />
        <span style={{ fontVariant: "small-caps" }}>outage</span>
    </div>
} />

const win = (typeof window == "undefined" ? {} : window) as any;

export const RateAnimation: KaceyView<{
    data: any[],
    fullscreen?: boolean
    // barData: BarData,
    // params: typeof defaultSimulationParams
}> =
    ({ data, fullscreen }) => {
        const boxSize = 8;
        const windowSize = 40;
        const bucketSize = 1000;
        const rateDict = useMemo(() => groupBy((data || []).filter(d => d.event == "requested"), (e) => Math.round(e.updated / bucketSize)), [data]);

        const hfactor = useMemo(() => Math.max(...Object.values(rateDict).map(d => d.length)), [rateDict]);

        const [_maxValue, setMaxValue] = useState(10);
        const [hasOutage, setHasOutage] = useState(false);

        const maxValue = hasOutage ? 0 : _maxValue;

        const times = useMemo(() => Object.keys(rateDict).sort().map(t => Number(t)), [rateDict])
        const rates = useMemo(() => times.map(k => rateDict[k.toString()]), [times, rateDict])

        const startTime = useMemo(() => data[0].updated || 0, [data]);
        const [currentTime, setCurrentTime] = useState<number>(startTime);

        const bucketId = (t: number) => Math.round(t / bucketSize);

        const ct = bucketId(currentTime);
        const currIx = useMemo(() => times.findIndex(t => t > ct), [ct, times])

        const window = useMemo(() => {
            const normal = times.slice(Math.max(currIx - windowSize, 0), Math.max(currIx, 0));
            const needMore = currIx < windowSize;
            const circular = needMore ? times.slice(currIx - windowSize) : [];
            // const circular = times < win
            const w = [...circular, ...normal];
            return [...w].reverse();
        }, [currIx, times])

        const buffer = useRef(0);
        const extras = useRef([] as { count: number, time: number, maxValue: number }[])
        // const max{ rtotal } / { rwindow.length * maxValue }


        const [lwindow, rwindow, rtotal, extra] = useMemo(() => {
            const half = windowSize / 2;
            const [l, r] = [window.slice(0, half), window.slice(half + 1, windowSize)];

            const rtotal = r.reduce((r, c) => (rateDict[c.toString()]?.length || 0) + r, 0);

            const first = rateDict[r[0]];
            const firstlen = first.length || 0;
            // if(rfirst > windowSize)

            const extra = buffer.current > 0 && firstlen < maxValue ? maxValue - firstlen : 0;

            const ec = extras.current[0] || {};
            if (bucketId(first[0].updated) != ec.time) {
                extras.current.unshift({ count: extra, time: bucketId(first[0].updated), maxValue });

                // const rindex = r.reduce((r, c) => { return { ...r, [c]: true } }, {} as Record<number, boolean>);
                extras.current = extras.current.slice(0, windowSize); //.filter(e => rindex[e.time]); //.slice(0, windowSize);

                buffer.current = Math.max(buffer.current + (firstlen - maxValue), 0);
            }
            return [l, r, rtotal, { count: extra, time: bucketId(first[0].updated) }];
        }, [window, windowSize, rateDict, maxValue]);

        const updTime = useCallback(() => {
            if (!window.length || (currIx < 0))
                setCurrentTime(startTime);
            else
                setCurrentTime(t => t + bucketSize);
        }, [setCurrentTime, window, startTime, currIx])

        useRafInterval(() => {
            updTime();
        }, 60);

        const [hasWebsemaphore, _setHasWebsemaphore] = useState(true);

        const height = hfactor * boxSize;

        const panelStyle = {
            // border: "1px solid grey",
            justifyContent: "space-between",
            alignItems: "stretch",
            height: hfactor * boxSize,
            // position: "relative" as any,
            // width: "100%"
        };

        const panelSpace = {
            // background: "#FFEED5",
            width: 180,
            height: hfactor * boxSize,
            minHeight: boxSize * hfactor,
            fontSize: 40,
            justifyContent: "space-around",
            padding: 0,
            alignItems: "end",
            // border: "1px solid black",
        };
        const archCompStyle = {
            border: "1px solid black",
            padding: "20px 10px 20px",
            height: height,
            boxSizing: "border-box" as any,
            // maxWidth: 180,
            // minWidth: 170,
            width: 180,
            maxHeight: height,
            borderRadius: 5,
            boxShadow: "0 0 5px 5px lightgray",
            background: "white"
        };
        const chartWrapperStyle = {
            alignItems: "flex-end", justifyContent: "space-around", minHeight: boxSize * hfactor, maxHeight: boxSize * 50,
            // width: 200
        };
        const boxStyle = (color?: string, extra?: CSSProperties | false) => ({
            boxSizing: "border-box" as any,
            maxWidth: boxSize,
            maxHeight: boxSize,
            minWidth: boxSize,
            minHeight: boxSize,
            background: color || "blue",
            border: "1px solid #fff8f0",
            borderRadius: boxSize,
            ...(extra || {})
        });
        const maxCapacityStyle = {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: "red 2px solid"
        };


        const containerRef = useRef<any>();
        const containerSize = useSize(containerRef) || { width: 0, height: 1 };
        const contentRef = useRef<any>();
        const contentSize = useSize(contentRef) || { width: 0, height: 1 };


        const normal = {
            width: hasWebsemaphore ? 1148 : fullscreen ? 800 : 1148
        };
        const sw = containerSize.width; //Math.max(containerSize.width, win.innerWidth);


        const scaleFactor = sw / normal.width; // Math.max(sw /normal.width, containerSize.height / contentSize.height); //(contentSize.height / contentSize.width)


        const setHasWebsemaphore = (val: boolean) => {
            _setHasWebsemaphore(val);
            buffer.current = 0;
        }

        useKeyPress(['w'], () => {
            setHasWebsemaphore(!hasWebsemaphore);
        });

        useKeyPress(['s'], () => {
            setHasOutage(!hasOutage);
        });

        useKeyPress(['a'], () => {
            setMaxValue(mv => mv - 1);
        });
        useKeyPress(['d'], () => {
            setMaxValue(mv => mv + 1);
        });

        return <>
            {/* {JSON.stringify(containerSize)}
            {JSON.stringify(contentSize)}
             */}
            {/* {scaleFactor}  {win.innerWidth} content:  {JSON.stringify(contentSize)} { containerSize.width } */}
            {/* startTime: {startTime} currentTime: {currentTime} wind: {window.join(" ")} */}
            <div className="scale-to-container-width-container" ref={containerRef}>
                <div style={{
                    marginTop: hasWebsemaphore ? 0 : fullscreen ? -150 : 0,
                    width: hasWebsemaphore ? 1148 : fullscreen ? 800 : 1148,
                    left: 5,
                    height: 650 * scaleFactor,
                    boxSizing: "border-box",
                    transform: `scale(${scaleFactor})`
                }}
                    ref={contentRef}
                    className="scale-to-container-width"
                >
                    <Row wrap={false} style={{ ...panelStyle }} justify="space-between">
                        <Space align="center" style={panelSpace} onClick={() => setHasOutage(!hasOutage)}>

                            <Space direction="vertical" align="center" style={{ ...archCompStyle, justifyContent: "space-between" }}>
                                <div style={{ fontSize: 20 }} className="text-center">
                                    <b>Clients</b>
                                </div>
                                <div style={{ fontSize: 35, color: "#777777", paddingTop: 15 }}>
                                    <MobileOutlined />
                                    <LaptopOutlined />
                                    <CloudServerOutlined />
                                    <CameraOutlined />
                                </div>

                                <div>
                                    &nbsp;
                                </div>

                            </Space>

                            {/* </Space> */}
                        </Space>
                        {/* ingress */}
                        <ArrowAnimation />
                        <Row wrap={false} style={{ ...chartWrapperStyle }}>
                            {lwindow.map(k => rateDict[k.toString()]).map((d, i) =>
                                <div style={{ width: boxSize + 1 }} key={d[0].updated + "l"}>
                                    {(maxValue > d.length) && range(0, Math.max(maxValue - d.length, 0)).map((k, j) => <div key={k + "" + j + "ws"}

                                        style={{ ...boxStyle("#fff8f0", !k && maxCapacityStyle) }}>&nbsp;</div>)}
                                    {range(0, d.length).map((k, j) =>
                                        <div style={{
                                            ...boxStyle((j <= d.length - maxValue - 1) ? "crimson" : undefined, (k == d.length - maxValue) && maxCapacityStyle),
                                        }}
                                            key={k}
                                        >
                                            &nbsp;
                                        </div>)}
                                </div>

                            )}
                        </Row>

                        <div>

                            <ArrowAnimation />
                            {!hasWebsemaphore && !maxValue && <Outage left={-20} />}
                        </div>
                        {/* websemaphore */}
                        {/* <Space direction="vertical" style={{ padding: 0, justifyContent: "end" }}> */}
                        {hasWebsemaphore ?
                            <>
                                <Space direction="horizontal">
                                    <div style={panelSpace}>
                                        <div style={{ ...archCompStyle, width: 180, fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                                            WebSemaphore
                                            <div style={{ marginTop: 7, marginBottom: 14 }}>
                                                <div className="semaphore-logo-static" style={{
                                                    maxWidth: 4,
                                                    fontSize: 12,
                                                    borderColor: "darkorange", color: "darkorange", boxShadow: "none",
                                                    margin: "0 auto",
                                                    borderWidth: "10px"
                                                }} />
                                            </div>

                                            <div style={{ textAlign: "center" }}>Buffer</div>
                                            <div style={{ fontSize: 20, textAlign: "center", marginBottom: 7 }}>
                                                {buffer.current}
                                            </div>

                                            <Row style={{ justifyContent: "end", position: "relative", left: -4 }}>
                                                <Row style={{ justifyContent: "end" }}>{
                                                    buffer?.current ? range(0, Math.min(buffer?.current, 285)).map((_, i) =>
                                                        <div key={i} style={{ ...boxStyle("darkorange") }}>&nbsp;</div>) : <></>
                                                }</Row>
                                            </Row>
                                        </div>
                                    </div>
                                    {/* {rtotal} / {rwindow.length * maxValue} */}


                                </Space>
                                <ArrowAnimation />


                                {/* egress  */}
                                {/* {lwindow.length - rtotal} */}
                                <Row
                                    wrap={false}
                                    style={chartWrapperStyle}
                                    // onClick={() => setHasOutage(!hasOutage)}
                                    onMouseOver={() => setHasOutage(true)}
                                    onMouseOut={() => setHasOutage(false)}
                                >
                                    {!maxValue && <Outage />}
                                    {(rwindow.length ? rwindow : range(0, lwindow.length))
                                        .map(k => rateDict[k.toString()]).map((d, i) => {
                                            const localMaxValue = extras.current[i]?.maxValue || maxValue;
                                            const extra = localMaxValue > 0 ? (extras.current[i]?.count || 0) : 0;
                                            return <div style={{ width: boxSize + 1 }} key={d[0].updated + "r"}>
                                                {(localMaxValue > d.length + extra) && range(0, Math.max(localMaxValue - d.length - extra, 0)).map((k, j) =>
                                                    <div key={k + "" + j + "ws"}
                                                        style={{ ...boxStyle("#fff8f0", !k && maxCapacityStyle) }}>&nbsp;</div>)
                                                }
                                                {range(0, extra).map(k =>
                                                    <div style={
                                                        boxStyle("darkorange",
                                                            !k && (d.length + extra >= localMaxValue) && (d.length < localMaxValue) && maxCapacityStyle)
                                                    }
                                                        key={k}
                                                    >&nbsp;</div>)}


                                                {range(0, Math.min(d.length, localMaxValue)).map(k => <div style={boxStyle("", !k && (d.length >= localMaxValue) && !extra && maxCapacityStyle)} key={k}>&nbsp;</div>)}
                                            </div>
                                        })
                                    }
                                </Row>
                                {/* upstream */}
                                <ArrowAnimation />
                            </> :
                            <>

                                {/* </Space> */}
                            </>
                        }
                        <Space align="center" style={panelSpace} onClick={() => setHasOutage(!hasOutage)}>
                            <Space direction="vertical" align="center" style={{ ...archCompStyle, justifyContent: "space-between" }}>
                                <div>
                                    <div style={{ fontSize: 20 }} className="text-center"><b>Backends</b><br /><br /></div>
                                    AI / Analytic Backend / SaaS / Database / Physical Resource
                                </div>
                                <div style={{ fontSize: 35, color: "#777777", paddingTop: 0 }}>
                                    <CloudServerOutlined />
                                    <ControlOutlined />
                                    <AndroidOutlined />
                                    <DatabaseOutlined />
                                </div>
                                <div style={{ position: "static" }}>
                                    <Button
                                        style={{
                                            visibility: fullscreen ? "hidden" : "visible",
                                            width: 120,
                                            height: "6em",
                                            border: "3px solid",
                                            borderColor: hasOutage ? "darkgreen" : "red",
                                            color: hasOutage ? "darkgreen" : "red",
                                            fontWeight: "bold",
                                            // position: "absolute",
                                            bottom: 0,
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                            // left: 10
                                        }}
                                        onClick={() => setHasOutage(!hasOutage)}>
                                        <ExclamationCircleOutlined /><br />
                                        {hasOutage ? "Fix" : "Simulate"}<br />Outage
                                    </Button>
                                </div>
                            </Space>
                        </Space>
                    </Row >
                    {!fullscreen ?
                        <>
                            <div style={{
                                width: "100%",
                                maxHeight: 150,
                                boxSizing: "border-box",
                                padding: 30,
                                fontSize: 30,
                                textAlign: "left",
                                marginTop: 30,
                                border: "darkorange 3px solid",
                                borderRadius: 10,
                                fontFamily: "Roboto",
                                fontWeight: "bold",
                            }}
                            >
                                <Row>
                                    <Col span={5}>
                                        Max concurrency:
                                    </Col>
                                    <Col span={2}>
                                        {maxValue}
                                    </Col>
                                    <Col span={17}>
                                        <Slider
                                            className="large-slider"
                                            // dotSize="large"
                                            trackStyle={{ minHeight: 30 }}
                                            handleStyle={{ width: 30, minHeight: 30 }}
                                            railStyle={{ minHeight: 30 }}
                                            min={0}
                                            max={hfactor}
                                            defaultValue={maxValue}
                                            onChange={(e) =>
                                                setMaxValue(e)
                                            }
                                            style={{ width: "100%", marginTop: 5 }}
                                        />
                                    </Col>
                                </Row>
                                {fullscreen ? <></> :
                                    <Row style={{ marginTop: 15 }}>
                                        <Col span={5}>
                                            With WebSemaphore:
                                        </Col>
                                        <Col span={2}>
                                            {hasWebsemaphore ? "yes" : "no"}
                                        </Col>
                                        <Col span={17}>
                                            <Switch checked={hasWebsemaphore} onChange={(e) => setHasWebsemaphore(e)} />
                                        </Col>
                                    </Row>}
                            </div>
                        </>
                        :
                        <></>
                    }
                </div>
                {/* {!fullscreen && !hasWebsemaphore ? <Row style={{ marginBottom: 300 }}>&nbsp;</Row> : <></>} */}
            </div>
        </>
    }


import staticSimulationData from "./staticSimulationData.json";

export const RateAnimationDefault: KaceyView<{ fullscreen?: boolean }> = ({ fullscreen }) => {
    return <RateAnimation data={staticSimulationData} fullscreen={fullscreen} />
}
